export const isMobile = () => {
  return window.innerWidth < 768;
};

export const formatDate = (date) => {
  const d = new Date(date);
  const day = d.getDate().length < 10 ? `0${d.getDate()}` : d.getDate();
  const month =
    d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  return `${day}/${month}/${d.getFullYear()}`;
};

export const formatDateTime = (date) => {
  const d = new Date(date);
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  const month =
    d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  const year = d.getFullYear();
  const hour = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
  const minute = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
  return `${day}/${month}/${year} ${hour}:${minute}`;
};

export const isSimilarString = (a, b) => {
  const aLength = a.length;
  const bLength = b.length;
  const maxLength = Math.max(aLength, bLength);
  let similar = 0;
  for (let i = 0; i < maxLength; i++) {
    if (a[i] === b[i]) similar++;
  }
  return similar / maxLength > 0.5;
};

export const getDatesBetweenDates = (date1, date2) => {
  let dates = [];
  const startDate = new Date(date1);
  const endDate = new Date(date2);
  while (startDate < endDate) {
    const newDate = new Date(startDate);
    dates = [...dates, newDate.toISOString().split("T")[0]];
    startDate.setDate(startDate.getDate() + 1);
  }
  dates = [...dates, endDate.toISOString().split("T")[0]];
  return dates;
};

export const getFirstDateOfMonth = () => {
  const d = new Date();
  const firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
  return firstDay;
};

export const getLastDateOfMonth = () => {
  const d = new Date();
  const lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
  return lastDay;
};

export const substractMonth = () => {
  const d = new Date();
  const newDate = new Date(d.setMonth(d.getMonth() - 1));
  return newDate;
};

export const moneyFormatter = (value) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "CLP",
  });
  return formatter.format(value).replace(",", ".");
};

export const isNumber = (value) => {
  if (value === null) return false;
  if (value.length === 0) return false;
  return !isNaN(value);
};

export const rutValidator = {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  isValid: function (rutCompleto) {
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
    var tmp = rutCompleto.split("-");
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == "K") digv = "k";
    return rutValidator.dv(rut) == digv;
  },
  dv: function (T) {
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : "k";
  },
};

export const isValidEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const transitDays = (shipment) => {
  const startDate = new Date(shipment.sold_at);
  let endDate = new Date();
  if (shipment.status === "entregado") endDate = new Date(shipment.updated_at);
  const diffTime = Math.abs(endDate - startDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays - 1;
};
