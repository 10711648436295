<template>
  <div class="grid">
    <Toast />
    <div class="col-12 lg:col-12">
      <div class="card card-w-title statistics">
        <Coverage caption="Origen" :setCoverage="setOriginCoverage" />
        <Coverage caption="Destino" :setCoverage="setDestinationCoverage" />
        <div id="form">
          <div class="formgroup-inline">
            <div class="field">
              <span class="p-float-label">
                <InputText class="p-inputtext" v-model="sizes.height" />
                <label for="company-rut">Alto</label>
              </span>
            </div>
            <div class="field">
              <span class="p-float-label">
                <InputText class="p-inputtext" v-model="sizes.width" />
                <label for="company-rut">Ancho</label>
              </span>
            </div>
          </div>
          <div class="formgroup-inline">
            <div class="field">
              <span class="p-float-label">
                <InputText class="p-inputtext" v-model="sizes.length" />
                <label for="company-rut">Largo</label>
              </span>
            </div>
            <div class="field">
              <span class="p-float-label right">
                <InputText
                  class="p-inputtext"
                  v-model="sizes.volumetric_weight"
                />
                <label for="company-rut">Peso Volumétrico</label>
              </span>
            </div>
          </div>
          <div class="formgroup-inline">
            <div class="field">
              <span class="p-float-label">
                <InputText
                  class="p-inputtext"
                  v-model="sizes.physical_weight"
                />
                <label for="company-rut">Peso Físico</label>
              </span>
            </div>
          </div>
          <CourierPricing
            :originCoverage="originCoverage"
            :destinationCoverage="destinationCoverage"
            :sizes="sizes"
            :showToast="showToast"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import Coverage from "./common/Coverage.vue";
import CourierPricing from "./common/CourierPricing.vue";
import { useToast } from "primevue/usetoast";

const toast = useToast();
const originCoverage = ref(null);
const destinationCoverage = ref(null);

const sizes = ref({
  height: null,
  width: null,
  length: null,
  volumetric_weight: null,
  physical_weight: null,
});

const setOriginCoverage = (coverage) => {
  originCoverage.value = coverage;
};

const setDestinationCoverage = (coverage) => {
  destinationCoverage.value = coverage;
};

const showToast = (severity, summary, detail) => {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 7000,
  });
};
</script>
<style scoped>
.formgroup-inline {
  margin-top: 10px;
}
</style>
