<template>
  <div class="field" v-if="!props.isShipping">
    <Button label="Cotizar" @click="getPricing" />
  </div>
  <ProgressSpinner
    v-if="loading"
    style="width: 50px; height: 50px; margin-top: 20px; margin-left: 40%"
    class="spinner"
    strokeWidth="8"
    fill="var(--surface-ground)"
    animationDuration=".5s"
  />
  <DataTable
    :value="prices"
    tableStyle="min-width: 50rem; margin-top:20px"
    v-if="prices.length > 0"
  >
    <Column header="Courier">
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <img
            :src="`/layout/images/couriers/${data.icon}`"
            :alt="data.name"
            class="p-mr-2"
            width="70"
          />
        </div>
      </template>
    </Column>
    <Column field="weight" header="Peso"></Column>
    <Column field="description" header="Servicio"></Column>
    <Column field="price" header="Valor"></Column>
    <Column header="Seleccionar" v-if="props.isShipping">
      <template #body="{ data }">
        <i
          class="pi pi-send clickable"
          @click="props.setPrice(data)"
          style="color: #326eb5"
        ></i>
      </template>
    </Column>
  </DataTable>
</template>
<script setup>
import { ref, defineProps } from "vue";
import { loadPrices, priceMapping } from "@/services/courier";
import { getCouriers } from "@/api/courier";
import { findByUser } from "@/api/customer";
import { readUserData } from "@/services/user";
import ProgressSpinner from "primevue/progressspinner";
import { isNumber } from "@/services/utils";
import { moneyFormatter } from "@/services/utils";

const prices = ref([]);
const loading = ref(false);
const declaredWorth = ref(0);
const customer = ref({});
const user = readUserData();

const props = defineProps({
  originCoverage: Object,
  destinationCoverage: Object,
  sizes: Object,
  showToast: Function,
  isShipping: Boolean,
  setPrice: Function,
});

const getCustomerData = async () => {
  try {
    const response = await findByUser(user.id);
    customer.value = response.data;
  } catch (err) {
    props.showToast(
      "error",
      "Error",
      "Ha ocurrido un error al cargar los datos"
    );
  }
};

const showValidationError = () => {
  props.showToast("error", "Error", "Todos los campos son requeridos");
};
const isValidData = () => {
  if (props.originCoverage === null) {
    showValidationError();
    return false;
  }
  if (props.destinationCoverage === null) {
    showValidationError();
    return false;
  }
  if (!isNumber(props.sizes.physical_weight)) {
    props.showToast("error", "Error", "El peso físico debe ser numérico");
    return false;
  }
  if (!isNumber(props.sizes.volumetric_weight)) {
    props.showToast("error", "Error", "El peso volumétrico debe ser numérico");
    return false;
  }
  if (!isNumber(props.sizes.height)) {
    props.showToast("error", "Error", "El alto debe ser numérico");
    return false;
  }
  if (!isNumber(props.sizes.width)) {
    props.showToast("error", "Error", "El ancho debe ser numérico");
    return false;
  }
  if (!isNumber(props.sizes.length)) {
    props.showToast("error", "Error", "El largo debe ser numérico");
    return false;
  }
  return true;
};

const getPricing = async () => {
  try {
    if (!isValidData()) return;
    loading.value = true;
    const couriersData = await getCouriers(customer.value.id);
    const couriers = couriersData.data;
    const enabledCouriers = couriers.filter((courier) => courier.enabled);
    if (enabledCouriers.length === 0) {
      props.showToast(
        "error",
        "Error",
        "No tiene ningún courier habilitado, por favor revise sus datos en Configuración, Couriers."
      );
      loading.value = false;
      return;
    }
    await Promise.all(
      enabledCouriers.map(async (courier) => {
        const { data } = await loadPrices(
          props.originCoverage.countyCode,
          props.destinationCoverage.countyCode,
          props.sizes,
          declaredWorth.value,
          courier.id
        );
        const pricesArray = priceMapping(
          data,
          props.originCoverage.countyCode,
          props.destinationCoverage.countyCode,
          courier
        );
        prices.value = pricesArray;
        prices.value.forEach((price) => {
          price.price = moneyFormatter(price.price);
        });
        loading.value = false;
      })
    );
  } catch (err) {
    console.log(err);
    props.showToast(
      "error",
      "Error",
      "Ha ocurrido un error obteniendo los precios"
    );
    loading.value = false;
  }
};

const startLoading = async () => {
  loading.value = true;
  await getCustomerData();
  if (props.isShipping) await getPricing();
  loading.value = false;
};

startLoading();
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
