import axios from "./axios-instance";

export const findByUser = (userId) => {
  return axios.get(`/customers/admin-contact/by-user/${userId}`);
};

export const saveCustomer = (payload) => {
  return axios.post(`/customers/admin-contact`, payload);
};

export const toogleSaleChannel = (payload) => {
  return axios.put(`/customers/sale-channel`, payload);
};

export const enableSaleChannel = (payload) => {
  return axios.post(`/customers/sale-channel`, payload);
};
