<template>
  <div class="formgroup-inline">
    <div class="field">
      <span class="p-float-label">
        <Dropdown
          v-model="selectedRegion"
          :options="regions"
          optionLabel="name"
          style="width: 300px"
        />
        <label for="company-rut">Región de {{ props.caption }}</label>
      </span>
    </div>
    <div class="field">
      <span class="p-float-label">
        <Dropdown
          v-model="selectedCity"
          :options="cities"
          optionLabel="countyName"
          style="width: 300px"
        />
        <label for="company-rut">Comuna de {{ props.caption }}</label>
      </span>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, defineProps } from "vue";
import { getRegions } from "../../api/region";
import { getAreas } from "@/api/courier";

const regions = ref([]);
const selectedRegion = ref(null);
const cities = ref([]);
const selectedCity = ref(null);

const loadRegions = async () => {
  const { data } = await getRegions();
  regions.value = data;
  console.log(regions);
};
loadRegions();

const loadCities = async () => {
  const { data } = await getAreas(1, selectedRegion.value.code);
  cities.value = data.coverageAreas;
};

const props = defineProps({
  caption: String,
  setCoverage: Function,
});

watch(
  () => selectedRegion.value,
  () => {
    loadCities();
  }
);

watch(
  () => selectedCity.value,
  () => {
    props.setCoverage(selectedCity.value);
  }
);
</script>

<style scoped></style>
