import { getPricing } from "@/api/courier";
export const loadPrices = async (
  originCountyCode,
  destinationCountyCode,
  sizes,
  declaredWorth,
  courierId
) => {
  const payload = {
    originCountyCode: originCountyCode,
    destinationCountyCode: destinationCountyCode,
    package: {
      weight: sizes.volumetric_weight,
      height: sizes.height,
      width: sizes.width,
      length: sizes.length,
    },
    productType: 3,
    declaredWorth: declaredWorth,
  };
  return await getPricing(courierId, payload);
};

export const priceMapping = (
  data,
  originCountyCode,
  destinationCountyCode,
  selectedCourier
) => {
  const prices = [];
  data.courierServiceOptions.forEach((element) => {
    const price = {
      icon: selectedCourier.icon,
      price: element.serviceValue,
      weight: element.finalWeight,
      description: element.serviceDescription,
      originCountyCode: originCountyCode,
      destinationCountyCode: destinationCountyCode,
      courierId: selectedCourier.id,
    };
    prices.push(price);
  });
  return prices;
};
