import axios from "./axios-instance";

export const getCouriers = (customerId) => {
  return axios.get(`/couriers/${customerId}`);
};

export const toogleCourier = (payload) => {
  return axios.put(`/customers/courier`, payload);
};

export const getPricing = (courierId, payload) => {
  return axios.post(`/couriers/price/${courierId}`, payload);
};

export const getAreas = (courierId, regionCode) => {
  return axios.get(`/couriers/areas/${courierId}/${regionCode}`);
};

export const newShipment = (courierId, payload) => {
  return axios.post(`/couriers/new-shipment/${courierId}`, payload);
};

export const getAttributes = (courierId) => {
  return axios.get(`/couriers/attributes/${courierId}`);
};

export const print = (courierId, payload) => {
  return axios.post(`/couriers/labels/${courierId}`, payload);
};
